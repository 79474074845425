import styled, { css, keyframes } from 'styled-components'

import PropTypes from 'prop-types'

const prime = '#5ac8f5'
const second = '#ffffff'

const SVG = styled.svg`
  position: absolute;
  top: -4000px;
  left: -4000px;
`

const bubbleAnimations = []
for (let bubble = 1; bubble <= 10; bubble++) {
  const move = keyframes`
        0% {
            transform: translate(0, 0);
        }
        99% {
            transform: translate(0, -${Math.random() * 80 + 50}px);
        }
        100% {
            transform: translate(0, 0);
            opacity: 0;
        }
    `
  bubbleAnimations.push(move)
}

const AnimatedButtonStyled = styled.button`
  padding: 12px 24px;
  font-size: 16px;
  border: none;
  color: ${second};
  filter: url('#gooey');
  position: relative;
  background-color: ${prime};

  &:focus {
    outline: none;
  }

  .bubbles {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    .bubble {
      background-color: ${prime};
      border-radius: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      z-index: -1;

      ${[...Array(10).keys()].map(
        (bubble, i) => css`
          &:nth-child(${bubble + 1}) {
            left: ${Math.random() * 90 + 10}px;
            width: 25px;
            height: 25px;
            animation: ${bubbleAnimations[i]} ${3 + bubble * 0.02}s infinite;
            animation-delay: ${bubble * 0.2}s;
          }
        `
      )}
    }
  }
`

export const AnimatedButton = ({ text }) => (
  <>
    <SVG xmlns="http://www.w3.org/2000/svg" version="1.1">
      <defs>
        <filter id="gooey">
          <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
          <feColorMatrix
            in="blur"
            type="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
            result="highContrastGraphic"
          />
          <feComposite
            in="SourceGraphic"
            in2="highContrastGraphic"
            operator="atop"
          />
        </filter>
      </defs>
    </SVG>

    <AnimatedButtonStyled>
      {text}
      <span className="bubbles">
        <span className="bubble"></span>
        <span className="bubble"></span>
        <span className="bubble"></span>
        <span className="bubble"></span>
        <span className="bubble"></span>
        <span className="bubble"></span>
        <span className="bubble"></span>
        <span className="bubble"></span>
        <span className="bubble"></span>
        <span className="bubble"></span>
      </span>
    </AnimatedButtonStyled>
  </>
)

AnimatedButton.propTypes = {
  text: PropTypes.string.isRequired
}

export default AnimatedButton
