import PlanOptionView from './PlanOptionView'
import PropTypes from 'prop-types'

const PlanOptionsList = ({ options }) => {
  return (
    <div className="mb-32">
      {options?.map((option, index) => (
        <PlanOptionView key={`option-${index}`} option={option} />
      ))}
    </div>
  )
}

PlanOptionsList.propTypes = {
  options: PropTypes.array.isRequired
}

export default PlanOptionsList
