import { rules, services } from '../_constants'

import { ServicesContainer } from '../components'
import { ServicesProvider } from '../contexts'

const ServicesWidget = () => {
  return (
    <ServicesProvider services={services} rules={rules}>
      <ServicesContainer />
    </ServicesProvider>
  )
}

export default ServicesWidget
