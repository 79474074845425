import { Col, Radio, Row } from 'antd'

import PropTypes from 'prop-types'

const PaymentPeriodSelect = ({ onChange, subscriptionType, className }) => {
  return (
    <Row className={className}>
      <Col span="24">
        <Radio.Group
          onChange={onChange}
          value={subscriptionType}
          style={{ width: '100%' }}>
          <Row>
            <Col xs={24} md={4} lg={3} xl={3}>
              <Radio value="MONTHLY">Pay monthly</Radio>
            </Col>
            <Col xs={24} md={6} lg={5} xl={4}>
              <Radio value="QUARTERLY">
                Pay quarterly{' '}
                <sup style={{ fontSize: '12px', color: 'orange' }}>Save 5%</sup>
              </Radio>
            </Col>
            <Col xs={24} md={7} lg={6} xl={5}>
              <Radio value="SEMI_ANNUALLY">
                Pay semi-annually{' '}
                <sup style={{ fontSize: '12px', color: 'orange' }}>
                  Save 10%
                </sup>
              </Radio>
            </Col>
            <Col xs={24} md={7} lg={5} xl={4}>
              <Radio value="ANNUALLY">
                Pay annually{' '}
                <sup style={{ fontSize: '12px', color: 'orange' }}>
                  Save 15% 🔥
                </sup>
              </Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Col>
    </Row>
  )
}

PaymentPeriodSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  subscriptionType: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default PaymentPeriodSelect
