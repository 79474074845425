import PlansList from './PlansList'
import PropTypes from 'prop-types'
import { Typography } from 'antd'

const { Title, Text } = Typography

const ServiceView = ({ service }) => {
  const { title, description, plans, isSubscription } = service

  return (
    <div className="mb-96">
      <Title level={3}>
        <img
          src="divided_symbol.svg"
          alt="divided symbol"
          height="24px"
          className="mr-16"
        />{' '}
        {title}
      </Title>
      <Text className="mb-32 d-inline-block fs-18 mw-724">{description}</Text>
      <PlansList plans={plans} isSubscription={isSubscription} />
    </div>
  )
}

ServiceView.propTypes = {
  service: PropTypes.object.isRequired
}

export default ServiceView
