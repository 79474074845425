import { useEffect, useState } from 'react'

import { useServices } from '../../contexts'

const usePriceCalculator = ({ priceCoef, subscriptionType }) => {
  const { rules } = useServices()
  const { baseSalary } = rules
  const [calculatedPricePerMonth, setCalculatedPricePerMonth] = useState(null)
  const [calculatedPricePerPeriod, setCalculatedPricePerPeriod] = useState(null)

  useEffect(() => {
    const subscriptionTypeNameMap = {
      MONTHLY: 'monthlyPaymentDiscount',
      QUARTERLY: 'quarterlyPaymentDiscount',
      SEMI_ANNUALLY: 'semiAnnuallyPaymentDiscount',
      ANNUALLY: 'annuallyPaymentDiscount'
    }
    const subscriptionTypeMonthsNumberMap = {
      MONTHLY: 1,
      QUARTERLY: 3,
      SEMI_ANNUALLY: 6,
      ANNUALLY: 12
    }
    if (subscriptionType) {
      const ruleName = subscriptionTypeNameMap[subscriptionType]
      const monthsNumber = subscriptionTypeMonthsNumberMap[subscriptionType]
      const coef = rules[ruleName]
      const newPrice = priceCoef * rules.baseSalary * coef
      setCalculatedPricePerMonth(parseFloat(newPrice.toFixed(2)))
      setCalculatedPricePerPeriod(
        parseFloat((newPrice * monthsNumber).toFixed(2))
      )
    } else {
      setCalculatedPricePerPeriod(priceCoef * baseSalary)
    }
  }, [priceCoef, rules, subscriptionType, baseSalary])

  return {
    calculatedPricePerMonth,
    calculatedPricePerPeriod
  }
}

export default usePriceCalculator
