import ServiceView from './ServiceView'
import { useServices } from '../contexts'

const ServicesList = () => {
  const { services } = useServices()

  return (
    <>
      {!services && <div>No services</div>}
      {services?.map((service, index) => (
        <ServiceView key={`service-${index}`} service={service} />
      ))}
    </>
  )
}

export default ServicesList
