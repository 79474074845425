import PropTypes from 'prop-types'
import { Typography } from 'antd'

const { Text } = Typography

const PlanPaymentOptionView = ({ text, value }) => {
  return (
    <div>
      <span className="mr-8">●</span>
      <Text className="fs-16 lh-28">
        {text}: {value}
      </Text>
    </div>
  )
}

PlanPaymentOptionView.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
}

export default PlanPaymentOptionView
