import { useEffect, useState } from 'react'

import AppearanceContext from './AppearanceContext'
import PropTypes from 'prop-types'

const AppearanceProvider = ({ children }) => {
  // const [isDark, setIsDark] = useState(
  //   localStorage.getItem('isDark') === 'false' ||
  //     !localStorage.getItem('isDark')
  //     ? false
  //     : true
  // )
  const [isDark, setIsDark] = useState(true)

  useEffect(() => {
    // Set theme class to html tag
    if (!isDark) {
      document.querySelector('html').classList.add('light')
      document.querySelector('html').classList.remove('dark')
    } else {
      document.querySelector('html').classList.add('dark')
      document.querySelector('html').classList.remove('light')
    }
  }, [isDark])

  const toggleTheme = () => {
    setIsDark(!isDark)
    localStorage.setItem('isDark', !isDark)
  }

  return (
    <AppearanceContext.Provider value={{ isDark, toggleTheme }}>
      {children}
    </AppearanceContext.Provider>
  )
}

AppearanceProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default AppearanceProvider
