import styled, { keyframes } from 'styled-components'

import { Typography } from 'antd'

const { Title } = Typography

const GradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`

const GradientTitle = styled(Title)`
  background: linear-gradient(
    270deg,
    #ff5e62,
    #ff9966,
    #00d2ff,
    #3a7bd5,
    #00d2ff,
    #ff9966,
    #ff5e62
  );
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${GradientAnimation} 10s ease-in-out infinite;
`

export default GradientTitle
