import { Layout } from 'antd'
import PropTypes from 'prop-types'

const { Content } = Layout

const ServicesLayout = ({ children }) => {
  return (
    <Layout style={{ minHeight: '100%', alignItems: 'center' }}>
      <Content style={{ maxWidth: '1280px', width: '100%', padding: '16px' }}>
        {children}
      </Content>
    </Layout>
  )
}

ServicesLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default ServicesLayout
