import { AppConfigProvider } from 'contexts/AppConfig'
import { AppearanceContainer } from 'modules/Appearance/containers'
import { ServicesWidget } from 'modules/Services/widgets'

function App() {
  return (
    <AppConfigProvider>
      <AppearanceContainer>
        <ServicesWidget />
      </AppearanceContainer>
    </AppConfigProvider>
  )
}

export default App
