const SERVICES = [
  {
    title: 'Chat support',
    description:
      "No more bottlenecks or confusions. I'm just a chat message away, ready to answer your questions and resolve issues in real time. ",
    isSubscription: true,
    plans: [
      {
        title: 'LITE',
        priceCoef: 3,
        options: [
          {
            text: 'Response time',
            value: '48 hours'
          },
          {
            text: 'Availability hours',
            value: '10:00-18:00'
          },
          {
            text: 'Availability days',
            value: 'Mon-Fri'
          },
          {
            text: 'Number of requests included',
            value: '8'
          }
        ],
        extraOptions: [
          {
            text: 'Extra support request',
            priceCoef: 0.4
          }
        ]
      },
      {
        title: 'BASIC',
        priceCoef: 11,
        options: [
          {
            text: 'Response time',
            value: '24 hours'
          },
          {
            text: 'Availability hours',
            value: '10:00-18:00'
          },
          {
            text: 'Availability days',
            value: 'Mon-Fri'
          },
          {
            text: 'Number of requests included',
            value: '20'
          }
        ],
        extraOptions: [
          {
            text: 'Extra support request',
            priceCoef: 0.6
          }
        ]
      },
      {
        title: 'PREMIUM',
        priceCoef: 23,
        options: [
          {
            text: 'Response time',
            value: '4 hours'
          },
          {
            text: 'Availability hours',
            value: '10:00-18:00'
          },
          {
            text: 'Availability days',
            value: 'Mon-Sun'
          },
          {
            text: 'Number of requests included',
            value: '40'
          }
        ],
        extraOptions: [
          {
            text: 'Extra support request',
            priceCoef: 0.69
          }
        ]
      }
    ]
  },
  {
    title: 'Video consultation',
    description:
      "For more complex problems, schedule a video call with me. It's like having a dedicated troubleshooter who understands your business inside out.",
    isSubscription: false,
    plans: [
      {
        title: 'QUICK',
        priceCoef: 0.35,
        options: [
          {
            text: 'Duration',
            value: '15 minutes'
          },
          {
            text: 'Availability hours',
            value: '10:00-18:00'
          },
          {
            text: 'Availability days',
            value: 'Mon-Fri'
          }
        ],
        extraOptions: [
          {
            text: 'Extra minute',
            priceCoef: 0.026
          },
          {
            text: 'Consultation summary',
            priceCoef: 0.3
          }
        ],
        packs: [
          {
            text: 'SM pack',
            number: 5,
            discount: 0.95
          },
          {
            text: 'MD pack',
            number: 10,
            discount: 0.9
          },
          {
            text: 'LG pack',
            number: 20,
            discount: 0.85
          }
        ]
      },
      {
        title: 'BASIC',
        priceCoef: 0.75,
        options: [
          {
            text: 'Duration',
            value: '30 minutes'
          },
          {
            text: 'Availability hours',
            value: '10:00-18:00'
          },
          {
            text: 'Availability days',
            value: 'Mon-Fri'
          }
        ],
        extraOptions: [
          {
            text: 'Extra minute',
            priceCoef: 0.027
          },
          {
            text: 'Consultation summary',
            priceCoef: 0.43
          }
        ],
        packs: [
          {
            text: 'SM pack',
            number: 5,
            discount: 0.95
          },
          {
            text: 'MD pack',
            number: 10,
            discount: 0.9
          },
          {
            text: 'LG pack',
            number: 20,
            discount: 0.85
          }
        ]
      },
      {
        title: 'PREMIUM',
        priceCoef: 1.69,
        options: [
          {
            text: 'Duration',
            value: '1 hour'
          },
          {
            text: 'Availability hours',
            value: '10:00-22:00'
          },
          {
            text: 'Availability days',
            value: 'Mon-Sun'
          }
        ],
        extraOptions: [
          {
            text: 'Extra minute',
            priceCoef: 0.031
          },
          {
            text: 'Consultation summary',
            priceCoef: 0.7
          }
        ],
        packs: [
          {
            text: 'SM pack',
            number: 5,
            discount: 0.95
          },
          {
            text: 'MD pack',
            number: 10,
            discount: 0.9
          },
          {
            text: 'LG pack',
            number: 20,
            discount: 0.85
          }
        ]
      }
    ]
  },
  {
    title: 'Preparation of Project Requirements Document (PRD)',
    description:
      " I provide a clear, detailed Project Requirements Document to ensure everyone's on the same page. Know what's expected and when, with precise time estimates.",
    isSubscription: false,
    plans: [
      {
        title: 'LITE',
        priceCoef: 7.45,
        options: [
          {
            text: '15 min video consultations',
            value: '1'
          },
          {
            text: '1 hour video consultations',
            value: '1'
          },
          {
            text: 'Revisions',
            value: '1'
          },
          {
            text: 'Delivery time',
            value: '2 days'
          },
          {
            text: 'Working days',
            value: 'Mon-Fri'
          },
          {
            text: 'User stories',
            value: 'up to 20'
          }
        ],
        extraOptions: [
          {
            text: 'One extra revision',
            priceCoef: 1
          },
          {
            text: 'Extra speed',
            priceCoef: 1.49
          }
        ],
        packs: [
          {
            text: 'SM pack',
            number: 5,
            discount: 0.95
          },
          {
            text: 'MD pack',
            number: 10,
            discount: 0.9
          },
          {
            text: 'LG pack',
            number: 20,
            discount: 0.85
          }
        ]
      },
      {
        title: 'NORMAL',
        priceCoef: 13.49,
        options: [
          {
            text: '15 min video consultations',
            value: '2'
          },
          {
            text: '1 hour video consultations',
            value: '1'
          },
          {
            text: 'Revisions',
            value: '2'
          },
          {
            text: 'Delivery time',
            value: '3 days'
          },
          {
            text: 'Working days',
            value: 'Mon-Fri'
          },
          {
            text: 'User stories',
            value: 'up to 40'
          }
        ],
        extraOptions: [
          {
            text: 'One extra revision',
            priceCoef: 1
          },
          {
            text: 'Extra speed',
            priceCoef: 2.7
          }
        ],
        packs: [
          {
            text: 'SM pack',
            number: 5,
            discount: 0.95
          },
          {
            text: 'MD pack',
            number: 10,
            discount: 0.9
          },
          {
            text: 'LG pack',
            number: 20,
            discount: 0.85
          }
        ]
      },
      {
        title: 'UNICORN',
        priceCoef: 25.11,
        options: [
          {
            text: '15 min video consultations',
            value: '2'
          },
          {
            text: '1 hour video consultations',
            value: '2'
          },
          {
            text: 'Revisions',
            value: '3'
          },
          {
            text: 'Delivery time',
            value: '4 days'
          },
          {
            text: 'Working days',
            value: 'Mon-Fri'
          },
          {
            text: 'User stories',
            value: 'up to 80'
          }
        ],
        extraOptions: [
          {
            text: 'One extra revision',
            priceCoef: 1
          },
          {
            text: 'Extra speed',
            priceCoef: 5.02
          }
        ],
        packs: [
          {
            text: 'SM pack',
            number: 5,
            discount: 0.95
          },
          {
            text: 'MD pack',
            number: 10,
            discount: 0.9
          },
          {
            text: 'LG pack',
            number: 20,
            discount: 0.85
          }
        ]
      }
    ]
  },
  {
    title: 'Cost estimations',
    description:
      "Budget uncertainty can be frustrating. I'll help estimate the project costs upfront to keep your financial planning on track.",
    isSubscription: false,
    plans: [
      {
        title: 'LITE',
        priceCoef: 3.75,
        options: [
          {
            text: '15 min video consultations',
            value: '1'
          },
          {
            text: 'Delivery time',
            value: '2 days'
          },
          {
            text: 'Working days',
            value: 'Mon-Fri'
          },
          {
            text: 'User stories',
            value: 'up to 20'
          }
        ],
        extraOptions: [
          {
            text: 'Extra speed',
            priceCoef: 0.75
          }
        ],
        packs: [
          {
            text: 'SM pack',
            number: 5,
            discount: 0.95
          },
          {
            text: 'MD pack',
            number: 10,
            discount: 0.9
          },
          {
            text: 'LG pack',
            number: 20,
            discount: 0.85
          }
        ]
      },
      {
        title: 'NORMAL',
        priceCoef: 8.03,
        options: [
          {
            text: '15 min video consultations',
            value: '1'
          },
          {
            text: '1 hour video consultations',
            value: '1'
          },
          {
            text: 'Delivery time',
            value: '3 days'
          },
          {
            text: 'Working days',
            value: 'Mon-Fri'
          },
          {
            text: 'User stories',
            value: 'up to 40'
          }
        ],
        extraOptions: [
          {
            text: 'Extra speed',
            priceCoef: 1.61
          }
        ],
        packs: [
          {
            text: 'SM pack',
            number: 5,
            discount: 0.95
          },
          {
            text: 'MD pack',
            number: 10,
            discount: 0.9
          },
          {
            text: 'LG pack',
            number: 20,
            discount: 0.85
          }
        ]
      },
      {
        title: 'UNICORN',
        priceCoef: 14.31,
        options: [
          {
            text: '15 min video consultations',
            value: '2'
          },
          {
            text: '1 hour video consultations',
            value: '1'
          },
          {
            text: 'Delivery time',
            value: '4 days'
          },
          {
            text: 'Working days',
            value: 'Mon-Fri'
          },
          {
            text: 'User stories',
            value: 'up to 80'
          }
        ],
        extraOptions: [
          {
            text: 'Extra speed',
            priceCoef: 2.86
          }
        ],
        packs: [
          {
            text: 'SM pack',
            number: 5,
            discount: 0.95
          },
          {
            text: 'MD pack',
            number: 10,
            discount: 0.9
          },
          {
            text: 'LG pack',
            number: 20,
            discount: 0.85
          }
        ]
      }
    ]
  },
  {
    title: 'SRS preparation',
    description:
      'Get comprehensive technical documentation for each project phase, prepared with meticulous attention to detail.',
    isSubscription: false,
    plans: [
      {
        title: 'PHASE',
        priceCoef: 16.15,
        options: [
          {
            text: 'Delivery time',
            value: '3 days'
          },
          {
            text: 'Working days',
            value: 'Mon-Fri'
          },
          {
            text: 'User stories',
            value: 'up to 20'
          },
          {
            text: 'Wireframes (not designs)',
            value: 'up to 10'
          },
          {
            text: '1h video consultation',
            value: '1'
          },
          {
            text: 'User journey diagram',
            value: '1'
          },
          {
            text: 'Data structure',
            value: '1'
          },
          {
            text: 'Pages list',
            value: '1'
          },
          {
            text: 'Components list',
            value: '1'
          },
          {
            text: 'Integrations list',
            value: '1'
          }
        ],
        extraOptions: [
          {
            text: 'Extra speed',
            priceCoef: 3.23
          }
        ],
        packs: [
          {
            text: 'SM pack',
            number: 5,
            discount: 0.95
          },
          {
            text: 'MD pack',
            number: 10,
            discount: 0.9
          },
          {
            text: 'LG pack',
            number: 20,
            discount: 0.85
          }
        ]
      }
    ]
  },
  {
    title: 'Technical lead',
    description:
      "As your technical lead, I maintain high-quality standards by overseeing your team's work. Trust in consistent, top-notch delivery.",
    isSubscription: true,
    plans: [
      {
        title: 'MONTH',
        priceCoef: 59.8,
        options: [
          {
            text: 'Projects',
            value: '1'
          },
          {
            text: 'Team chat support',
            value: '21 hours'
          },
          {
            text: '15 min video consultations',
            value: '4'
          },
          {
            text: '1h video consultations',
            value: '4'
          },
          {
            text: 'Code review',
            value: '29 hours'
          }
        ]
      }
    ]
  },
  {
    title: 'Project manager',
    description:
      'Keep your customer requests in control. As your project manager, I effectively distribute tasks among team members and monitor progress.',
    isSubscription: true,
    plans: [
      {
        title: 'MONTH',
        priceCoef: 34.8,
        options: [
          {
            text: 'Projects',
            value: '1'
          },
          {
            text: 'Team chat support',
            value: '21 hours'
          },
          {
            text: '15 min video consultations',
            value: '4'
          },
          {
            text: '1h video consultations',
            value: '4'
          },
          {
            text: 'Tasks distribution',
            value: '4 hours'
          }
        ]
      }
    ]
  },
  {
    title: 'TL & PM',
    description:
      "Streamline your operations with a dual role service. I'll efficiently manage tasks, oversee quality, and ensure smooth project progress. This bundled service offers smoother operations and significant cost savings.",
    isSubscription: true,
    plans: [
      {
        title: 'MONTH',
        priceCoef: 72.49,
        options: [
          {
            text: 'Projects',
            value: '1'
          },
          {
            text: 'Team chat support',
            value: '32 hours'
          },
          {
            text: '15 min video consultations',
            value: '6'
          },
          {
            text: '1h video consultations',
            value: '4'
          },
          {
            text: 'Code review',
            value: '29 hours'
          },
          {
            text: 'Tasks distribution',
            value: '4 hours'
          }
        ]
      }
    ]
  },
  {
    title: 'Independent software audit',
    description:
      'Want to ensure your project meets all quality criteria? I provide an unbiased software audit, pinpointing areas that need rectification.',
    isSubscription: false,
    plans: [
      {
        title: 'ONE',
        priceCoef: 42,
        options: [
          {
            text: 'Projects',
            value: '1'
          },
          {
            text: 'Delivery time',
            value: '8 days'
          },
          {
            text: 'Testing',
            value: '16 hours'
          },
          {
            text: 'Code quality verification',
            value: '6 hours'
          },
          {
            text: 'Accessability verification',
            value: '4 hours'
          },
          {
            text: 'Security verification',
            value: '4 hours'
          },
          {
            text: 'Report',
            value: '1'
          }
        ],
        packs: [
          {
            text: 'SM pack',
            number: 5,
            discount: 0.95
          },
          {
            text: 'MD pack',
            number: 10,
            discount: 0.9
          },
          {
            text: 'LG pack',
            number: 20,
            discount: 0.85
          }
        ]
      }
    ]
  },
  {
    title: 'Contractors coordination',
    description:
      "Coordination can be a challenge, especially when your business and contractors are working towards a common goal. As your mediator, I ensure seamless communication and synchronization of activities. I will not only oversee contractor work but also facilitate effective dialogue between both parties. Whether it's answering their queries, checking their availability, or ensuring their satisfaction, my role is to ensure a smooth, efficient, and productive working relationship. Experience the ease of having someone who understands both your business intricacies and the contractor's perspective.",
    isSubscription: true,
    plans: [
      {
        title: 'ONE PER MONTH',
        priceCoef: 1,
        options: [
          {
            text: 'Availability tracking',
            value: '8'
          },
          {
            text: 'Projects distribution',
            value: 'unlimited'
          },
          {
            text: 'Q&A sessions',
            value: 'unlimited'
          }
        ]
      }
    ]
  },
  {
    title: 'Software developer assessment',
    description:
      "Get a fair evaluation of your contractors' technical level and the value they bring for their cost.",
    isSubscription: false,
    plans: [
      {
        title: 'ONE',
        priceCoef: 1.1,
        options: [
          {
            text: '30 min technical interview',
            value: '1'
          },
          {
            text: 'Report',
            value: '1'
          }
        ]
      }
    ]
  },
  {
    title: 'Project comprehensive jump-start',
    description:
      'From creating a landing page to a web application with minimal marketable features, I can kickstart your startup project in just 35 days.',
    isSubscription: false,
    plans: [
      {
        title: 'PROJECT',
        priceCoef: 166.67,
        options: [
          {
            text: 'Delivery time',
            value: '35 days'
          },
          {
            text: 'Revisions',
            value: '3'
          },
          {
            text: 'Landing page design and development',
            value: '1'
          },
          {
            text: 'Minimal marketable feature development',
            value: '1'
          },
          {
            text: 'Free bug fixing',
            value: '1 month'
          }
        ]
      }
    ]
  },
  {
    title: 'Software support',
    description:
      'After development, your project deserves reliable support. Count on me for prompt responses to client queries and ongoing maintenance.',
    isSubscription: true,
    plans: [
      {
        title: 'LITE',
        priceCoef: 1.45,
        options: [
          {
            text: 'Response time',
            value: '48 hours'
          },
          {
            text: 'Availability hours',
            value: '10:00-18:00'
          },
          {
            text: 'Availability days',
            value: 'Mon-Fri'
          },
          {
            text: 'Number of requests included',
            value: '4'
          }
        ]
      },
      {
        title: 'BASIC',
        priceCoef: 5.63,
        options: [
          {
            text: 'Response time',
            value: '24 hours'
          },
          {
            text: 'Availability hours',
            value: '10:00-18:00'
          },
          {
            text: 'Availability days',
            value: 'Mon-Fri'
          },
          {
            text: 'Number of requests included',
            value: '10'
          }
        ]
      },
      {
        title: 'PREMIUM',
        priceCoef: 16.25,
        options: [
          {
            text: 'Response time',
            value: '4 hours'
          },
          {
            text: 'Availability hours',
            value: '10:00-22:00'
          },
          {
            text: 'Availability days',
            value: 'Mon-Sun'
          },
          {
            text: 'Number of requests included',
            value: 'unlimited'
          }
        ]
      }
    ]
  }
]

export default SERVICES
