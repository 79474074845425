import PlanPackView from './PlanPackView'
import PropTypes from 'prop-types'

const PlanPacksList = ({ packs, price }) => {
  return (
    <div>
      {packs?.map((pack, index) => (
        <PlanPackView key={`pack-${index}}`} pack={pack} price={price} />
      ))}
    </div>
  )
}

PlanPacksList.propTypes = {
  packs: PropTypes.array.isRequired,
  price: PropTypes.number.isRequired
}

export default PlanPacksList
