import { Col, Row } from 'antd'

import PaymentPeriodSelect from './PaymentPeriodSelect'
import PlanView from './PlanView'
import PropTypes from 'prop-types'
import { useState } from 'react'

const PlansList = ({ plans, isSubscription }) => {
  const [subscriptionType, setSubscriptionType] = useState('MONTHLY')
  const onChange = (e) => setSubscriptionType(e.target.value)
  return (
    <>
      {isSubscription ? (
        <PaymentPeriodSelect
          className="mb-16"
          onChange={onChange}
          subscriptionType={subscriptionType}
        />
      ) : null}
      <Row gutter={[16, 16]}>
        {plans.map((plan, index) => (
          <Col key={`plan-${index}`} xs={24} lg={8}>
            <PlanView
              plan={plan}
              subscriptionType={isSubscription ? subscriptionType : 'MONTHLY'}
            />
          </Col>
        ))}
      </Row>
    </>
  )
}

PlansList.propTypes = {
  plans: PropTypes.array.isRequired,
  isSubscription: PropTypes.bool.isRequired
}

export default PlansList
