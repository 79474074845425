import PlanExtraOptionView from './PlanExtraOptionView'
import PropTypes from 'prop-types'

const PlanExtraOptionsList = ({ extraOptions }) => {
  return (
    <div>
      {extraOptions?.map((option, index) => (
        <PlanExtraOptionView key={`extra-option-${index}`} option={option} />
      ))}
    </div>
  )
}

PlanExtraOptionsList.propTypes = {
  extraOptions: PropTypes.array.isRequired
}

export default PlanExtraOptionsList
