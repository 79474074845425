import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { useServices } from '../contexts'

const { Text } = Typography

const PlanPackView = ({ pack, price }) => {
  const { rules } = useServices()
  const { currency } = rules
  const { text, number, discount } = pack

  const priceTotal = parseFloat((price * discount * number).toFixed(2))
  const pricePerItem = parseFloat((price * discount).toFixed(2))

  return (
    <div>
      <span className="mr-8">★</span>
      <Text>
        {text} [{number} items]: {priceTotal} {currency}
      </Text>{' '}
      <Text type="secondary">
        ({pricePerItem} {currency} / item)
      </Text>
    </div>
  )
}

PlanPackView.propTypes = {
  pack: PropTypes.object.isRequired,
  price: PropTypes.number.isRequired
}

export default PlanPackView
