import AppConfigContext from './AppConfigContext'
import { useContext } from 'react'

const useAppConfig = () => {
  const context = useContext(AppConfigContext)
  if (!context) {
    throw new Error('useAppConfig must be used within a AppConfigContext')
  }
  return context
}

export default useAppConfig
