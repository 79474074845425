const RULES = {
  monthlyPaymentDiscount: 1,
  quarterlyPaymentDiscount: 0.95,
  semiAnnuallyPaymentDiscount: 0.9,
  annuallyPaymentDiscount: 0.85,
  fortyFiveDaysAfterPaymentCoef: 1.1,
  threeMonthsInstallmentCoef: 1.2,
  baseSalary: 42,
  currency: '€'
}

export default RULES
