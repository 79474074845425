import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { usePriceCalculator } from '../hooks'
import { useServices } from '../contexts'

const { Text } = Typography

const PlanPrice = ({ priceCoef, subscriptionType }) => {
  const { rules } = useServices()
  const { baseSalary, currency } = rules
  const { calculatedPricePerMonth, calculatedPricePerPeriod } =
    usePriceCalculator({ priceCoef, subscriptionType })

  const oldPrice = priceCoef * baseSalary

  return (
    <>
      <Text strong className="d-block mt-16" style={{ fontSize: '20px' }}>
        {calculatedPricePerPeriod} {currency}
      </Text>
      {subscriptionType && subscriptionType !== 'MONTHLY' ? (
        <>
          <Text delete type="secondary">
            {oldPrice} {currency}
          </Text>{' '}
          <Text type="secondary">
            {calculatedPricePerMonth} {currency} / month
          </Text>
        </>
      ) : null}
    </>
  )
}

PlanPrice.propTypes = {
  priceCoef: PropTypes.number.isRequired,
  subscriptionType: PropTypes.string
}

export default PlanPrice
