import { Card, Divider, Typography } from 'antd'

import PlanExtraOptionsList from './PlanExtraOptionsList'
import PlanOptionsList from './PlanOptionsList'
import PlanPacksList from './PlanPacksList'
import PlanPaymentOptionsList from './PlanPaymentOptionsList'
import PlanPrice from './PlanPrice'
import PropTypes from 'prop-types'
import { useServices } from '../contexts'

const { Title } = Typography

const PlanView = ({ plan, subscriptionType }) => {
  const { rules } = useServices()
  const { baseSalary } = rules
  const { title, priceCoef, options, extraOptions, packs } = plan

  const price = priceCoef * baseSalary

  return (
    <Card className="h-100">
      <Title level={4} className="mt-0">
        {title}
      </Title>
      <PlanOptionsList options={options} />
      <PlanPrice priceCoef={priceCoef} subscriptionType={subscriptionType} />
      {extraOptions?.length > 0 ? (
        <>
          <Divider style={{ borderBlockStartColor: 'rgb(50, 50, 50)' }} />
          <PlanExtraOptionsList extraOptions={extraOptions} />
        </>
      ) : null}
      {subscriptionType && subscriptionType === 'MONTHLY' ? (
        <>
          <Divider style={{ borderBlockStartColor: 'rgb(50, 50, 50)' }} />
          <PlanPaymentOptionsList price={price} />
        </>
      ) : null}
      {packs?.length > 0 ? (
        <>
          <Divider style={{ borderBlockStartColor: 'rgb(50, 50, 50)' }} />
          <PlanPacksList packs={packs} price={price} />
        </>
      ) : null}
    </Card>
  )
}

PlanView.propTypes = {
  plan: PropTypes.object.isRequired,
  subscriptionType: PropTypes.string
}

export default PlanView
