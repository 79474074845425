import PropTypes from 'prop-types'
import ServicesContext from './ServicesContext'

const ServicesProvider = ({ children, services, rules }) => {
  return (
    <ServicesContext.Provider value={{ services, rules }}>
      {children}
    </ServicesContext.Provider>
  )
}

ServicesProvider.propTypes = {
  children: PropTypes.node.isRequired,
  services: PropTypes.array.isRequired,
  rules: PropTypes.object.isRequired
}

export default ServicesProvider
