import { AppearanceContext, AppearanceProvider } from '../../contexts'
import { ConfigProvider, theme } from 'antd'

import PropTypes from 'prop-types'
import { useAppConfig } from 'contexts/AppConfig'

const AppearanceContainer = ({ children }) => {
  const { appearance } = useAppConfig()
  return (
    <AppearanceProvider>
      <AppearanceContext.Consumer>
        {({ isDark }) => (
          <ConfigProvider
            theme={{
              algorithm: isDark ? theme.darkAlgorithm : theme.defaultAlgorithm,
              token: isDark
                ? appearance.theme.dark.token
                : appearance.theme.default.token,
              components: isDark
                ? appearance.theme.dark.components
                : appearance.theme.default.components
            }}>
            {children}
          </ConfigProvider>
        )}
      </AppearanceContext.Consumer>
    </AppearanceProvider>
  )
}

AppearanceContainer.propTypes = {
  children: PropTypes.node.isRequired
}

export default AppearanceContainer
