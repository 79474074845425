import PlanPaymentOptionView from './PlanPaymentOptionView'
import PropTypes from 'prop-types'
import { useServices } from '../contexts'

const PlanPaymentOptionsList = ({ price }) => {
  const { rules } = useServices()
  const {
    currency,
    fortyFiveDaysAfterPaymentCoef,
    threeMonthsInstallmentCoef
  } = rules

  const postPaidPrice = parseFloat(
    (price * fortyFiveDaysAfterPaymentCoef).toFixed(2)
  )
  const installmentPrice = parseFloat(
    ((price * threeMonthsInstallmentCoef) / 3).toFixed(2)
  )

  return (
    <div>
      <PlanPaymentOptionView
        text="Postpaid price after 45 days"
        value={`${postPaidPrice} ${currency}`}
      />
      <PlanPaymentOptionView
        text="Installment for three months"
        value={`${installmentPrice} ${currency} / mo`}
      />
    </div>
  )
}

PlanPaymentOptionsList.propTypes = {
  price: PropTypes.number.isRequired
}

export default PlanPaymentOptionsList
