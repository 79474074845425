import PropTypes from 'prop-types'
import { Typography } from 'antd'

const { Text } = Typography

const PlanOptionView = ({ option }) => {
  const { text, value } = option
  return (
    <div>
      <span className="mr-8">⌾</span>
      <Text className="fs-16 lh-28">
        {text}: {value}
      </Text>
    </div>
  )
}

PlanOptionView.propTypes = {
  option: PropTypes.object.isRequired
}

export default PlanOptionView
