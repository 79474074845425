import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { useServices } from '../contexts'

const { Text } = Typography

const PlanExtraOptionView = ({ option }) => {
  const { rules } = useServices()
  const { baseSalary, currency } = rules
  const { text, priceCoef } = option

  const price = parseFloat((priceCoef * baseSalary).toFixed(2))

  return (
    <div>
      <span className="mr-8">＋</span>
      <Text className="fs-16 lh-28">{text}:</Text>{' '}
      <Text strong>
        {price} {currency}
      </Text>
    </div>
  )
}

PlanExtraOptionView.propTypes = {
  option: PropTypes.object.isRequired
}

export default PlanExtraOptionView
