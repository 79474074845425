import { Button, Col, Row, Typography } from 'antd'
import { SendOutlined, VideoCameraOutlined } from '@ant-design/icons'

// import AnimatedButton from './AnimatedButton'
import GradientTitle from './GradientTitle'
import ServicesLayout from './ServicesLayout'
import ServicesList from './ServicesList'

const { Text } = Typography

const ServicesContainer = () => {
  return (
    <ServicesLayout>
      <Row>
        <Col>
          <img src="divided_logo.svg" alt="Divided by lekssoft" height="42px" />
        </Col>
      </Row>
      <Row className="mt-128">
        <Col span="24" className="t-center">
          <GradientTitle>
            Meet Divided - your new developer as a service platform
          </GradientTitle>
        </Col>
        <Col span="24" className="t-center">
          <Text
            style={{
              fontSize: '20px',
              maxWidth: '724px',
              display: 'inline-block'
            }}>
            We&apos;re here to completely transform the way outsourcing
            companies interact with developers, making work conditions better
            for everyone.
          </Text>
          <Text
            style={{
              fontSize: '20px',
              maxWidth: '724px',
              display: 'inline-block'
            }}>
            Simple, seamless, and efficient - welcome to a new era of
            collaborative development.
          </Text>
        </Col>
      </Row>
      <Row className="mt-64 mb-128" justify="center" gutter={[16, 16]}>
        <Col>
          <Button
            href="https://calendly.com/lekssoft/30min"
            target="_blank"
            type="text"
            size="large"
            icon={<VideoCameraOutlined />}>
            Schedule a call
          </Button>
        </Col>
        <Col>
          <Button
            href="https://t.me/alexpassss"
            target="_blank"
            type="primary"
            size="large"
            icon={<SendOutlined />}>
            Make an order
          </Button>
        </Col>
      </Row>
      <ServicesList />
    </ServicesLayout>
  )
}

export default ServicesContainer
