import AppConfigContext from './AppConfigContext'
import PropTypes from 'prop-types'
import appearanceConfig from '_settings/appearance'
import menuConfig from '_settings/menu'
import routingConfig from '_settings/routing'
import { useState } from 'react'

const AppConfigProvider = ({ children }) => {
  const [appearance, setAppearance] = useState(appearanceConfig)
  const [menu, setMenu] = useState(menuConfig)
  const [routing, setRouting] = useState(routingConfig)

  return (
    <AppConfigContext.Provider
      value={{
        appearance,
        setAppearance,
        menu,
        setMenu,
        routing,
        setRouting
      }}>
      {children}
    </AppConfigContext.Provider>
  )
}

AppConfigProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default AppConfigProvider
