import ServicesContext from './ServicesContext'
import { useContext } from 'react'

const useServices = () => {
  const context = useContext(ServicesContext)

  if (context === undefined) {
    throw new Error('useServices must be used within a ServicesProvider')
  }

  return context
}

export default useServices
